let isProd = '';
let isTest = '';
if (window.location.href.indexOf("agents.itwgo.in") > -1) {
    isProd = true
}else if(window.location.href.indexOf("demo.itwglobal.com") > -1){
    isTest =  true;
}else{
    isProd = false
    isTest =  false;
}
let SUPERVISION_BASE_URL,
    API_BASE_URL,
    ACCESS_TOKEN,
    REFRESH_TOKEN,
    LOGIN_DATA,
    TOKEN_TYPE,
    FLIGHT_IMAGES,
    PROFILE_IMAGE,
    ADMIN_IMAGE,
    FILE_PATH,
    STATE,
    IGST,
    CGST,
    SGST,
    GST,
    CESSGST,
    LOGO_WIDTH,
    LOGO_HEIGHT,
    R_KEY = null,
    MID = null,
    paytmHost = null;


if (isProd) {
    SUPERVISION_BASE_URL = 'https://mis.itwgo.in/';
    API_BASE_URL = 'https://www.itwgo.in/agentAPI/';
    ACCESS_TOKEN = 'access_token';
    REFRESH_TOKEN = 'refresh_token';
    LOGIN_DATA = 'login_data';
    TOKEN_TYPE = 'token_type';
    FLIGHT_IMAGES = '../../../assets/airline-logo/'
    PROFILE_IMAGE = API_BASE_URL + 'extras/custom/TMX1512291534825461/images/';
    ADMIN_IMAGE = SUPERVISION_BASE_URL + 'extras/custom/TMX1512291534825461/images/';
    FILE_PATH = API_BASE_URL + 'extras/custom/TMX1512291534825461/images/';
    STATE = "Karnataka";
    IGST = 0.18;
    CGST = 0.09;
    SGST = 0.09;
    GST = 0.18;
    CESSGST = 0.18;
    LOGO_WIDTH = 100;
    LOGO_HEIGHT = 70;
    // R_KEY = "rzp_live_x9MHkTwEa8wR7q"; //live,
    // MID = 'HIFITR57668070831864';
    // paytmHost = 'https://securegw.paytm.in';
    R_KEY = "rzp_test_tPwRiv3B8ENpHP"; //test
    MID = 'HIFITR90571255152529';
    paytmHost = 'https://securegw-stage.paytm.in';
}
else if (isTest) {
    API_BASE_URL = 'http://itwglobal.in/itwgo_v3/agent/';
    SUPERVISION_BASE_URL = 'http://itwglobal.in/itwgo_v3/supervision/';
    ACCESS_TOKEN = 'access_token';
    REFRESH_TOKEN = 'refresh_token';
    LOGIN_DATA = 'login_data';
    TOKEN_TYPE = 'token_type';
    FLIGHT_IMAGES = '../../../assets/airline-logo/'
    PROFILE_IMAGE = API_BASE_URL + 'extras/custom/TMX1512291534825461/images/';
    ADMIN_IMAGE = SUPERVISION_BASE_URL + 'extras/custom/TMX1512291534825461/images/';
    FILE_PATH = '/home/pk4fhgaba8iu/public_html/itwgo_v3/agent/extras/custom/TMX1512291534825461/images/';
    STATE = "Karnataka";
    IGST = 0.18;
    CGST = 0.09;
    SGST = 0.09;
    GST = 0.18;
    CESSGST = 0.18;
    LOGO_WIDTH = 100;
    LOGO_HEIGHT = 70;
    R_KEY = "rzp_test_tPwRiv3B8ENpHP"; //test
    MID = 'HIFITR90571255152529';
    paytmHost = 'https://securegw-stage.paytm.in';
}
else {
   //  API_BASE_URL = 'http://itwglobal.in/itwgo_v3/agent/';
//    API_BASE_URL = 'http://dev.api.b2b_v3.in/';
     API_BASE_URL = 'https://www.itwgo.in/agentAPI/';
    // SUPERVISION_BASE_URL = 'http://itwglobal.in/itwgo_v3/admin/';
    // SUPERVISION_BASE_URL = 'http://admin.itwglobal.in/';
    SUPERVISION_BASE_URL = 'https://mis.itwgo.in/';
    ACCESS_TOKEN = 'access_token';
    REFRESH_TOKEN = 'refresh_token';
    LOGIN_DATA = 'login_data';
    TOKEN_TYPE = 'token_type';
    FLIGHT_IMAGES = '../../../assets/airline-logo/'
    PROFILE_IMAGE = API_BASE_URL + 'extras/custom/TMX1512291534825461/images/';
    ADMIN_IMAGE = SUPERVISION_BASE_URL + 'extras/custom/TMX1512291534825461/images/';
    FILE_PATH = API_BASE_URL + 'extras/custom/TMX1512291534825461/images/';
    STATE = "Karnataka";
    IGST = 0.18;
    CGST = 0.09;
    SGST = 0.09;
    GST = 0.18;
    CESSGST = 0.18;
    LOGO_WIDTH = 100;
    LOGO_HEIGHT = 70;
    R_KEY = "rzp_test_tPwRiv3B8ENpHP"; //test
    MID = 'HIFITR90571255152529';
    paytmHost = 'https://securegw-stage.paytm.in';
}

export {
    SUPERVISION_BASE_URL,
    API_BASE_URL,
    ACCESS_TOKEN,
    REFRESH_TOKEN,
    LOGIN_DATA,
    TOKEN_TYPE,
    FLIGHT_IMAGES,
    PROFILE_IMAGE,
    ADMIN_IMAGE,
    FILE_PATH,
    STATE,
    IGST,
    CGST,
    SGST,
    GST,
    CESSGST,
    LOGO_WIDTH,
    LOGO_HEIGHT,
    R_KEY,
    MID,
    paytmHost
}